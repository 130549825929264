@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .bannerIcon {
    @apply w-16 h-16 bg-black bg-opacity-25 text-xl inline-flex items-center justify-center rounded-md shadow-shadowOne hover:-translate-y-1 transition-all duration-300;
  }

  /* Couleurs spécifiques */
  .reactBanner {
    @apply text-[#61DAFB];

  }

  .nodeBanner {
    @apply text-[#339933];

  }

  .typescriptBanner {
    @apply text-[#3178C6];

  }

  .tailwindBanner {
    @apply text-[#06B6D4];

  }

  .linkedinBanner {
    @apply text-[#0e76a8];

  }

  .resumeLi {
    @apply w-full h-20 bg-black bg-opacity-25 text-xl text-gray-300 flex justify-center cursor-pointer hover:bg-opacity-40 duration-300 items-center border-[1px] rounded-none;
  }

  .bgOpacity {
    @apply bg-black bg-opacity-40;
  }

  .contactInput {
    @apply w-full h-12 rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 focus:outline-none focus:border-b-transparent focus-visible:outline-designColor duration-300;
  }

  .contactTextArea {
    @apply w-full rounded-lg border-b-[1px] border-b-gray-600 bg-[#191b1e] text-lightText px-4 py-2 focus:outline-none focus:border-b-transparent focus-visible:outline-designColor duration-300 resize-none;
  }
}

.active {
  @apply text-white;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
